import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue14({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Reflection, auditing and taking control"
            pageSocialImage=""
            pageTitle="Issue #14"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #14"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "As we close in on the end of 2022, I’ve begun to reflect and consider how I’d like things to look this time next year.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at November"
                    content={[
                        {spans: [], text: "🪞 Reflection", type: "heading4"},
                        {spans: [], text: "This month I’ve been doing some reflection.", type: "paragraph"},
                        {spans: [], text: "I’ve felt distracted for a little while now – like I’m being pulled in all of these different directions.", type: "paragraph"},
                        {spans: [], text: "I’ve not really sat down to visualise where I’m headed – like what position would I like to be in 1 year, 5 years or even 10 years from now.", type: "paragraph"},
                        {spans: [], text: "And so anything that comes my way that seems important gets pilled onto my growing list of things to do – instead of questioning if this task is important in the first place.", type: "paragraph"},
                        {spans: [], text: "This has all come at a time when I came across the book “Essentialism”.", type: "paragraph"},
                        {spans: [], text: "In it, Greg shares the idea that we will always have to make trade-offs and that we can’t do everything.", type: "paragraph"},
                        {spans: [], text: "Like many, I try to fool myself that everything is important and that I can do it all if I am simply effective with my time and attention.", type: "paragraph"},
                        {spans: [], text: "🏔 Auditing my day-today", type: "heading4"},
                        {spans: [], text: "This has led me to audit what I do day-to-day.", type: "paragraph"},
                        {spans: [], text: "I started by thinking, if I didn’t have to worry about money what activities would I still be doing?", type: "paragraph"},
                        {spans: [], text: "Right off the bat, it was clear that I was prioritising non-essential stuff like posting to multiple social media accounts (more on this in a minute) vs essential things like exercising every day and drinking enough water.", type: "paragraph"},
                        {spans: [], text: "And so I created events in my calendar and prioritised the most important tasks first thing in the day.", type: "paragraph"},
                        {spans: [], text: "To me, this felt like the first step I needed to take.", type: "paragraph"},
                        {spans: [], text: "I still need to look at much larger tasks and projects and figure out what’s important and what isn’t.", type: "paragraph"},
                        {spans: [], text: "So expect more on this in next month’s newsletter.", type: "paragraph"},
                        {spans: [], text: "🧠 Figuring out what’s important", type: "heading4"},
                        {spans: [], text: "Take Stryve, I set myself the task of posting consistently to Instagram.", type: "paragraph"},
                        {spans: [], text: "Is this the most important thing I could be doing for Stryve right now to make it grow? Probably not.", type: "paragraph"},
                        {spans: [], text: "When I look at what’s most important right now, it’s to bring traffic to the website.", type: "paragraph"},
                        {spans: [], text: "Whilst my posts would get a decent amount of reach, the number of people heading to the website was minuscule. Plus the task of posting consistently every week took time away from more important tasks.", type: "paragraph"},
                        {spans: [], text: "If I look at traffic from Google, on the other hand, I’ve started to get a fairly decent amount of clicks to my website from the content I’ve sporadically been posting throughout this year.", type: "paragraph"},
                        {spans: [], text: "And if I do stop posting for a little while all of that traffic doesn’t just dry up like with Instagram.", type: "paragraph"},
                        {spans: [], text: "So from this perspective posting new recipes to my website is the number one thing I could be doing for Stryve right now.", type: "paragraph"},
                        {spans: [], text: "💬 Taiming social media", type: "heading4"},
                        {spans: [], text: "Social media is something I battle with constantly. It feels like a necessary evil, that I need to be a part of to grow any sort of business.", type: "paragraph"},
                        {spans: [], text: "But I feel like most of my time spent there is wasted time and energy.", type: "paragraph"},
                        {spans: [], text: "I go through phases where I delete most if not all social media apps from my phone, just for them to appear there again and suck up my time.", type: "paragraph"},
                        {spans: [], text: "So this is something I really need to zone in on and figure out how do I get the most out of these platforms with the least distraction.", type: "paragraph"},
                        {spans: [], text: "In a nutshell, I want to live an intentional life, a life where I choose what I do, a life by design not default.", type: "paragraph"},
                        {spans: [], text: "👷‍♂️ Work", type: "heading4"},
                        {spans: [], text: "If you remember in last month’s newsletter I said my schedule was clearing up. Fortunately, I managed to land a new project just as my previous one was coming to an end.", type: "paragraph"},
                        {spans: [], text: "This project is one of my highest fixed-price projects to date.", type: "paragraph"},
                        {spans: [], text: "Part of the project involved finding the best headless CMS’ (keeping costs in mind as it grows) for the project as my client previously used Webflow but is now hitting walls as his project has grown.", type: "paragraph"},
                        {spans: [], text: "Images play a massive role in this project, so the media library had to be top-notch and so we landed on DatoCMS (the first headless CMS I used back in 2017).", type: "paragraph"},
                        {spans: [], text: "There are a couple of really nice things that the media library has, like automatic smart tagging when you upload an image, it also picks out the main colours used in the image (which could easily be a useful feature for my client’s project) and the ability to replace an image instead of uploading a new one and replacing the old one.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 November goals"
                    checkList={[
                        {complete: true, text: "🏔 Do something outside your comfort zone"},
                        {complete: false, text: "📹 Post 3 posts to personal and Stryve Instagram accounts every week"},
                        {complete: false, text: "🌯 Add 6 new recipes to Stryve"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to December"
                    content={[
                        {spans: [], text: "For the past couple of years of freelancing, I’ve worked long into the Christmas period than I ever did working at a company.", type: "paragraph"},
                        {spans: [], text: "This year I’ve decided to take a decent holiday and finish client work on the 16th of December, leaving me with 17 days off.", type: "paragraph"},
                        {spans: [], text: "I won’t be sitting around watching TV for 17 days though.", type: "paragraph"},
                        {spans: [], text: "I’ll likely spend some of that time reading and learning from others who’ve reached a position that I feel I want to head in next year.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 December goals"
                    checkList={[
                        {complete: false, text: "⌛️ Figure out where I want to be 1 year, 5 years and 10 years from now"},
                        {complete: false, text: "🎯 Figure out what I should focus on to reach those visions"},
                        {complete: false, text: "🚀 Setup Astro and Sanity for my new website"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Take action, whilst it’s important to learn new things, being in a constant state of learning and not putting anything into action or out into the world won’t get you to where you want to be"
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/6f5b6e8d-0b1d-4a5a-88d8-9b1c794c8d91_2022-nov-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £9,000", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/8dbcea90-7b5c-4c93-b917-657f59c8e433_bird-of-paradise.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Bird of paradise", type: "heading4"},
                        {spans: [], text: "For a while, I’ve really wanted one of these plants, but in some garden centres, you’d pay over £200 for one this size. I managed to get this from Ikea for £55 though and really finishes off the vibe I wanted in my office.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.ikea.com/gb/en/p/strelitzia-potted-plant-bird-of-paradise-20466253/",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/67a4ea54-0c0f-4872-ae83-50e57eac7a05_book-essentialism.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Essentialism by Greg McKeown", type: "heading4"},
                        {spans: [], text: "I’ve gone a fairly long period this year where I’ve not felt like reading anything – likely because my mind was so full already. This book really aided in that process of which I knew I needed to do – figure out what’s actually important and forget the rest.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/Essentialism-Disciplined-Pursuit-Less/dp/B017TE0NWO/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue14;
